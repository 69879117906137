
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        


























































































































































::v-deep {
    .b-table .table th:first-child {
        border-radius: 5px 0 0 0;
    }

    .b-table .table th:last-child {
        border-radius: 0 5px 0 0;
    }

    @media screen and (max-width: 769px) {
        .table tbody tr td:nth-child(3) {
            display: none !important;
        }
    }
}
